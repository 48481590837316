// MainLayout.tsx
import React, { useState } from "react";
import SkinnySidebar from "../SkinnySidebar/SkinnySidebar";
import WideSidebar from "../WideSidebar/WideSidebar";
import TopNavBar from "../TopNavBar/TopNavBar";
import MainContent from "../MainContent/MainContent";
import MobileSidebarDialog from "../MobileSidebarDialog/MobileSidebarDialog";
import { Outlet, useLocation } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import Footer from "../Footer/Footer";
import { getNavigation } from "../../utils/navigationConfig/navigationConfig";
import { useSelector } from "react-redux";
import { selectUserAppSettings } from "../../modules/hub/features/UserAppSettings/selectors/userAppSettingsSelectors";
import IncompleteAccountSetup from "../../modules/hub/features/Auth/components/IncompleteAccountSetup/IncompleteAccountSetup";

interface MainLayoutProps {
  section: string | null;
}

const MainLayout: React.FC<MainLayoutProps> = ({ section }) => {
  const [sidebarOpenWide, setSidebarOpenWide] = useState(false);
  const location = useLocation();

  const navigationWide = getNavigation(section, location.pathname);

  const userAppSettings = useSelector(selectUserAppSettings);

  const isAccountSetupIncomplete = userAppSettings && !userAppSettings.roleName;

  return (
    <div className="flex h-screen">
      <SkinnySidebar />
      <WideSidebar navigation={navigationWide} section={section} />
      <div className="flex flex-1 flex-col">
        <TopNavBar onSidebarToggle={() => setSidebarOpenWide(true)} />
        <Breadcrumbs />
        <MainContent>
          {isAccountSetupIncomplete ? <IncompleteAccountSetup /> : <Outlet />}
        </MainContent>
        <Footer />
      </div>
      <MobileSidebarDialog
        navigationWide={navigationWide}
        section={section}
        setSidebarOpen={setSidebarOpenWide}
        sidebarOpen={sidebarOpenWide}
      />
    </div>
  );
};

export default MainLayout;
