import React from "react";
import { ReactNode } from "react";

interface MainContentProps {
  children: ReactNode;
}

const MainContent: React.FC<MainContentProps> = ({ children }) => (
  <main className="flex-1 flex flex-col overflow-y-auto">
    <div className="px-4 py-4 sm:px-6 lg:px-8">{children}</div>
  </main>
);

export default MainContent;
