import React, { useCallback, useMemo } from "react";
import { createCampaignFileColumns } from "../CampaignFileColumns/CampaignFileColumns";
import MainPageWrapper from "../../../../../../components/MainPageWrapper/MainPageWrapper";
import DataTable from "../../../../../../components/Datatable/Datatable";
import { useParams } from "react-router-dom";
import { CampaignFile } from "../../../../../../api/fetchCampaignFiles/types";
import { useCampaignFiles } from "../../hooks/useCampaignFile";
import { downloadCampaignFile } from "../../../../../../api/downloadCampaignFile/downloadCampaignFileApi";

const CampaignFilesList: React.FC = () => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const {
    files,
    totalCount,
    loading,
    error,
    pagination,
    sorting,
    columnVisibility,
    handlePaginationChange,
    handleSortingChange,
    handleColumnVisibilityChange,
  } = useCampaignFiles(Number(campaignId));

  const handleDownload = useCallback(async (file: CampaignFile) => {
    try {
      const requestData = {
        bucketName: file.bucketName,
        objectKey: file.objectKey,
      };
      const fileBlob = await downloadCampaignFile(requestData);

      // Create a URL for the blob and trigger a download
      const url = window.URL.createObjectURL(fileBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = file.originalFilename || "downloaded-file";
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download file:", error);
      alert("An error occurred while downloading the file.");
    }
  }, []);

  const columns = useMemo(
    () => createCampaignFileColumns({ handleDownload }),
    [handleDownload],
  );

  return (
    <MainPageWrapper error={error} loading={loading} title="Campaign Files">
      <DataTable
        columnVisibility={columnVisibility}
        columns={columns}
        data={files}
        onColumnVisibilityChange={handleColumnVisibilityChange}
        onPaginationChange={handlePaginationChange}
        onSortingChange={handleSortingChange}
        pageCount={Math.ceil(totalCount / pagination.pageSize)}
        pageIndex={pagination.pageIndex}
        pageSize={pagination.pageSize}
        sorting={sorting}
      />
    </MainPageWrapper>
  );
};

export default CampaignFilesList;
