import { ColumnDef } from "@tanstack/react-table";
import {
  Dropdown,
  DropdownButton,
  DropdownMenu,
  DropdownItem,
} from "../../../../../../components/catalyst-ui-kit/dropdown";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import React from "react";
import { User } from "../../slices/usersSlice";

interface CreateUsersColumnsProps {
  handleEdit: (uuid: string) => void;
  handleImpersonateUser: (uuid: string) => void; // New handler for switching user
}

export function createUsersColumns({
  handleEdit,
  handleImpersonateUser,
}: CreateUsersColumnsProps): ColumnDef<User>[] {
  return [
    {
      header: "ID",
      accessorKey: "id",
    },
    {
      header: "First Name",
      accessorKey: "firstName",
    },
    {
      header: "Last Name",
      accessorKey: "lastName",
    },
    {
      header: "Email",
      accessorKey: "email",
    },
    {
      header: "Salesforce ID",
      accessorKey: "salesforceId",
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => (
        <Dropdown>
          <DropdownButton className="p-1" plain>
            <EllipsisVerticalIcon className="h-5 w-5 text-zinc-500 dark:text-zinc-400" />
          </DropdownButton>
          <DropdownMenu>
            <DropdownItem
              onClick={() => handleEdit(row.original?.employeeUuid || "")}
            >
              Edit User
            </DropdownItem>
            <DropdownItem
              onClick={() => handleImpersonateUser(row.original?.uuid || "")}
            >
              Impersonate User
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      ),
      enableSorting: false,
    },
  ];
}
