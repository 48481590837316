import {
  ChartBarIcon,
  HomeIcon,
  UsersIcon,
  DocumentIcon,
  CircleStackIcon,
  PlusIcon,
  BuildingOffice2Icon,
} from "@heroicons/react/24/outline";
import { NavigationItem } from "../types";

const hubNavigation: NavigationItem[] = [
  {
    name: "Home",
    href: "/hub",
    icon: HomeIcon,
    current: false,
    internalName: "home",
    permissions: [],
  },
  {
    name: "Dashboards",
    href: "#",
    icon: ChartBarIcon,
    current: false,
    internalName: "dashboards",
    permissions: [],
    children: [
      {
        name: "Field Labor",
        href: "/hub/dashboards/field-labor",
        icon: UsersIcon,
        current: false,
        internalName: "field_labor",
        permissions: [],
      },
    ],
  },
  {
    name: "User Management",
    href: "#",
    icon: UsersIcon,
    current: false,
    internalName: "user_management",
    permissions: ["CAN_MANAGE_USERS"],
    children: [
      {
        name: "User List",
        href: "/hub/users",
        icon: UsersIcon,
        current: false,
        internalName: "user_list",
        permissions: ["CAN_VIEW_USERS"],
      },
      {
        name: "Create User",
        href: "/hub/users/create",
        icon: PlusIcon,
        current: false,
        internalName: "create_user",
        permissions: ["CAN_CREATE_USERS"],
      },
    ],
  },
  {
    name: "Company Management",
    href: "#",
    icon: BuildingOffice2Icon,
    current: false,
    internalName: "company_management",
    permissions: ["CAN_MANAGE_COMPANIES_ALL"],
    children: [
      {
        name: "Company List",
        href: "/hub/companies",
        icon: BuildingOffice2Icon,
        current: false,
        internalName: "company_list",
        permissions: ["CAN_MANAGE_COMPANIES_ALL"],
      },
      {
        name: "Create Company",
        href: "/hub/companies/create",
        icon: PlusIcon,
        current: false,
        internalName: "create_company",
        permissions: ["CAN_CREATE_COMPANIES"],
      },
    ],
  },
  {
    name: "Document Library",
    href: "/hub/document-library",
    icon: DocumentIcon,
    current: false,
    internalName: "document_library",
    permissions: ["CAN_ACCESS_DOCUMENT_LIBRARY"],
    children: [
      {
        name: "Monthly Balance Sheet",
        href: "/hub/document-library/monthly-balance-sheet",
        icon: DocumentIcon,
        current: false,
        internalName: "monthly_balance_sheet",
        permissions: ["CAN_ACCESS_MONTHLY_BALANCE_SHEET"],
      },
      {
        name: "Profit and Loss",
        href: "/hub/document-library/profit-and-loss",
        icon: DocumentIcon,
        current: false,
        internalName: "profit_and_loss",
        permissions: ["CAN_ACCESS_PROFIT_AND_LOSS"],
      },
      {
        name: "Transaction List",
        href: "/hub/document-library/transaction-list",
        icon: DocumentIcon,
        current: false,
        internalName: "transaction_list",
        permissions: ["CAN_ACCESS_TRANSACTION_LIST"],
      },
    ],
  },
  {
    name: "Data Connector",
    href: "/hub/data-connector",
    icon: CircleStackIcon,
    current: false,
    internalName: "data_connector",
    permissions: ["CAN_ACCESS_DATA_CONNECTOR"],
  },
];

export default hubNavigation;
