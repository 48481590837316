import React, { useCallback, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MainPageWrapper from "../../../../../../components/MainPageWrapper/MainPageWrapper";
import DataTable from "../../../../../../components/Datatable/Datatable";
import { useCampaignBatches } from "../../hooks/useCampaignBatches";
import { createCampaignBatchesColumns } from "../CampaignBatchColumns/CampaignBatchColumns";
import { useDownloadBatchProspectsCsv } from "../../../BatchProspectManagement/hooks/useDownloadBatchProspectsCsv";

const CampaignBatchList: React.FC = () => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const navigate = useNavigate();
  const numericCampaignId = useMemo(() => Number(campaignId), [campaignId]);

  const {
    batches,
    totalCount,
    loading,
    error,
    pagination,
    sorting,
    columnVisibility,
    handlePaginationChange,
    handleSortingChange,
    handleColumnVisibilityChange,
  } = useCampaignBatches(numericCampaignId);

  const {
    downloadCsv,
    loading: csvLoading,
    error: csvError,
  } = useDownloadBatchProspectsCsv();

  const handleViewProspects = useCallback(
    (batchId: number) => {
      navigate(
        `/stochastic/campaigns/${campaignId}/batches/${batchId}/prospects`,
      );
    },
    [navigate, campaignId],
  );

  const handleDownloadCsv = useCallback(
    (batchId: number) => {
      downloadCsv(batchId);
    },
    [downloadCsv],
  );

  const columns = useMemo(
    () =>
      createCampaignBatchesColumns({
        handleViewProspects,
        handleDownloadCsv,
      }),
    [handleViewProspects, handleDownloadCsv],
  );

  return (
    <MainPageWrapper
      error={error || csvError}
      loading={loading || csvLoading}
      title="Campaign Batches"
    >
      <DataTable
        columnVisibility={columnVisibility}
        columns={columns}
        data={batches}
        onColumnVisibilityChange={handleColumnVisibilityChange}
        onPaginationChange={handlePaginationChange}
        onSortingChange={handleSortingChange}
        pageCount={Math.ceil(totalCount / pagination.pageSize)}
        pageIndex={pagination.pageIndex}
        pageSize={pagination.pageSize}
        sorting={sorting}
      />
    </MainPageWrapper>
  );
};

export default CampaignBatchList;
