import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import MainPageWrapper from "../../../../../../components/MainPageWrapper/MainPageWrapper";
import DataTable from "../../../../../../components/Datatable/Datatable";
import { useBatchProspects } from "../../hooks/useBatchProspects";
import { createBatchProspectsColumns } from "../BatchProspectColumns/BatchProspectColumns";

const BatchProspectList: React.FC = () => {
  const { batchId } = useParams<{
    campaignId: string;
    batchId: string;
  }>();
  const numericBatchId = useMemo(() => Number(batchId), [batchId]);

  console.log("batchId", batchId);
  const {
    prospects,
    totalCount,
    loading,
    error,
    pagination,
    sorting,
    columnVisibility,
    handlePaginationChange,
    handleSortingChange,
    handleColumnVisibilityChange,
  } = useBatchProspects(numericBatchId);

  const columns = useMemo(() => createBatchProspectsColumns(), []);

  return (
    <>
      <MainPageWrapper error={error} loading={loading} title="Batch Prospects">
        <DataTable
          columnVisibility={columnVisibility}
          columns={columns}
          data={prospects}
          onColumnVisibilityChange={handleColumnVisibilityChange}
          onPaginationChange={handlePaginationChange}
          onSortingChange={handleSortingChange}
          pageCount={Math.ceil(totalCount / pagination.pageSize)}
          pageIndex={pagination.pageIndex}
          pageSize={pagination.pageSize}
          sorting={sorting}
        />
      </MainPageWrapper>
    </>
  );
};

export default BatchProspectList;
