import axios from "../axiosInstance";
import { UploadCampaignFilesResponse } from "./types";
import { AxiosProgressEvent } from "axios";

/**
 * Uploads a single file to a specific campaign.
 *
 * @param campaignId - The ID of the campaign to upload the file to.
 * @param file - The File object to be uploaded.
 * @param onUploadProgress - Optional callback to track upload progress.
 * @returns A promise that resolves when the upload is complete.
 */
export const uploadCampaignFile = async (
  campaignId: number,
  file: File,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
): Promise<UploadCampaignFilesResponse> => {
  const formData = new FormData();
  formData.append("file", file);

  await axios.post<void>(
    `/api/private/campaign/${campaignId}/upload-file`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    },
  );
};
