import React from "react";
import { Route } from "react-router-dom";
import MainLayout from "../../components/MainLayout/MainLayout";
import { AuthenticationGuard } from "../../components/AuthenticationGuard/AuthenticationGuard";

const TrainingPortalRoutes = (
  <Route
    element={
      <AuthenticationGuard
        component={() => <MainLayout section="training-portal" />}
      />
    }
    path="/training-portal"
  ></Route>
);

export default TrainingPortalRoutes;
