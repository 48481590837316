import React from "react";
import styles from "./LoadinIndicator.module.css";
import { useTheme } from "../../context/ThemeContext";

const LoadingIndicator: React.FC = () => {
  const { theme } = useTheme();
  const loaderColor = theme === "dark" ? "#B21E34" : "#17084A";

  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className={styles.growingBars}>
        <div
          className={styles.bar}
          style={{ backgroundColor: loaderColor }}
        ></div>
        <div
          className={styles.bar}
          style={{ backgroundColor: loaderColor }}
        ></div>
        <div
          className={styles.bar}
          style={{ backgroundColor: loaderColor }}
        ></div>
        <div
          className={styles.bar}
          style={{ backgroundColor: loaderColor }}
        ></div>
      </div>
    </div>
  );
};

export default LoadingIndicator;
