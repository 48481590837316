import { combineReducers } from "@reduxjs/toolkit";
import stochasticCustomersReducer from "../modules/stochastic/features/CustomerList/slices/stochasticCustomersSlice";
import stochasticProspectsReducer from "../modules/stochastic/features/ProspectList/slices/stochasticProspectsSlice";
import userAppSettingsReducer from "../modules/hub/features/UserAppSettings/slices/userAppSettingsSlice";
import quickBooksReportsReducer from "../modules/hub/features/DocumentLibrary/slices/quickBooksReportsSlice";
import usersReducer from "../modules/hub/features/UserManagement/slices/usersSlice";
import companiesReducer from "../modules/hub/features/CompanyManagement/slices/companiesSlice";
import editUserDetailsReducer from "../modules/hub/features/UserManagement/slices/editUserDetailsSlice";
import companyProfileReducer from "../modules/hub/features/UserAppSettings/slices/companyProfileSlice";
import userProfileReducer from "../modules/hub/features/UserAppSettings/slices/userProfileSlice";
import campaignListReducer from "../modules/stochastic/features/CampaignManagement/slices/campaignListSlice";
import campaignReducer from "../modules/stochastic/features/CampaignManagement/slices/campaignSlice";
import campaignBatchListReducer from "../modules/stochastic/features/CampaignBatchManagement/slices/campaignBatchListSlice";
import batchProspectReducer from "../modules/stochastic/features/BatchProspectManagement/slices/batchProspectSlice";
import campaignFilesReducer from "../modules/stochastic/features/CampaignFileManagement/slices/campaignFilesSlice";

const rootReducer = combineReducers({
  stochasticCustomers: stochasticCustomersReducer,
  stochasticProspects: stochasticProspectsReducer,
  userAppSettings: userAppSettingsReducer,
  quickBooksReports: quickBooksReportsReducer,
  users: usersReducer,
  editUserDetails: editUserDetailsReducer,
  companies: companiesReducer,
  companyProfile: companyProfileReducer,
  userProfile: userProfileReducer,
  campaignList: campaignListReducer,
  campaign: campaignReducer,
  campaignBatchList: campaignBatchListReducer,
  batchProspect: batchProspectReducer,
  campaignFiles: campaignFilesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
