import React from "react";
import { Row, flexRender } from "@tanstack/react-table";
import { TableBody, TableCell, TableRow } from "../../../catalyst-ui-kit/table";

interface DataTableBodyProps<TData extends object> {
  rows: Row<TData>[];
}

function DataTableBody<TData extends object>({
  rows,
}: DataTableBodyProps<TData>) {
  return (
    <TableBody>
      {rows.map((row) => (
        <TableRow key={row.id}>
          {row.getVisibleCells().map((cell) => (
            <TableCell key={cell.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}

export default DataTableBody;
