import React, { useMemo } from "react";
import { ReportType } from "../../../../../../api/fetchQuickBooksReports/types";
import { useQuickBooksReports } from "../../hooks/useQuickBooksReports";
import { createQuickBooksReportsColumns } from "../QuickBooksReportsColumns/QuickBooksReportsColumns";
import DataTable from "../../../../../../components/Datatable/Datatable";
import MainPageWrapper from "../../../../../../components/MainPageWrapper/MainPageWrapper";

const TransactionList: React.FC = () => {
  const {
    reports,
    totalCount,
    loading,
    error,
    sorting,
    pagination,
    columnVisibility,
    handleSortingChange,
    handlePaginationChange,
    handleColumnVisibilityChange,
    handleDownloadReport,
  } = useQuickBooksReports(ReportType.TRANSACTION_LIST);

  const columns = useMemo(
    () => createQuickBooksReportsColumns({ handleDownloadReport }),
    [handleDownloadReport],
  );

  return (
    <MainPageWrapper
      error={error}
      loading={loading}
      title="Transaction List Report"
    >
      <DataTable
        columnVisibility={columnVisibility}
        columns={columns}
        data={reports}
        onColumnVisibilityChange={handleColumnVisibilityChange}
        onPaginationChange={handlePaginationChange}
        onSortingChange={handleSortingChange}
        pageCount={Math.ceil(totalCount / pagination.pageSize)}
        pageIndex={pagination.pageIndex}
        pageSize={pagination.pageSize}
        sorting={sorting}
      />
    </MainPageWrapper>
  );
};

export default TransactionList;
