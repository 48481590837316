import React from "react";
import { Helmet } from "react-helmet-async";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

interface PageWrapperProps {
  title: string;
  loading: boolean;
  error: string | null;
  children: React.ReactNode;
}

const MainPageWrapper: React.FC<PageWrapperProps> = ({
  title,
  loading,
  children,
}) => {
  return (
    <div className="relative">
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <h1 className="text-2xl text-primary dark:text-secondary mb-4">
        {title}
      </h1>
      <hr className="mb-4" />

      {/*      {error && (
        <div style={{ color: "red", textAlign: "center" }}>{error}</div>
      )}*/}

      {children}

      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
          <LoadingIndicator />
        </div>
      )}
    </div>
  );
};

export default MainPageWrapper;
