import axios from "../axiosInstance";
import { DownloadCampaignFileRequest } from "./types";

/**
 * Downloads a campaign file from the server.
 *
 * @param requestData - The request parameters including bucket name and object key.
 * @returns A promise that resolves to a Blob representing the downloaded file.
 */
export const downloadCampaignFile = async (
  requestData: DownloadCampaignFileRequest,
): Promise<Blob> => {
  const response = await axios.get<Blob>(
    `/api/private/campaign/file/download`,
    {
      params: {
        bucketName: requestData.bucketName,
        objectKey: requestData.objectKey,
      },
      responseType: "blob",
    },
  );

  return response.data;
};
