import React, { useCallback, useMemo, useState } from "react";
import { useCampaigns } from "../../hooks/useCampaigns";
import MainPageWrapper from "../../../../../../components/MainPageWrapper/MainPageWrapper";
import DataTable from "../../../../../../components/Datatable/Datatable";
import EditCampaignDrawer from "../EditCampaignDrawer/EditCampaignDrawer";
import { useNavigate } from "react-router-dom";
import { createCampaignsColumns } from "../CampaignColumns/CampaignColumns";
import { useDispatch } from "react-redux";
import { fetchCampaignsAction } from "../../slices/campaignListSlice";

const CampaignList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    campaigns,
    totalCount,
    loading,
    error,
    pagination,
    sorting,
    columnVisibility,
    handlePaginationChange,
    handleSortingChange,
    handleColumnVisibilityChange,
    requestData,
  } = useCampaigns();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState<number | null>(
    null,
  );

  const handleEdit = useCallback((id: number) => {
    setSelectedCampaignId(id);
    setIsDrawerOpen(true);
  }, []);

  const handleViewBatches = useCallback(
    (id: number) => {
      navigate(`/stochastic/campaigns/${id}/batches`);
    },
    [navigate],
  );

  const handleViewFiles = useCallback(
    (id: number) => {
      navigate(`/stochastic/campaigns/${id}/files`);
    },
    [navigate],
  );

  const handleSave = useCallback(() => {
    setIsDrawerOpen(false);
    dispatch(fetchCampaignsAction(requestData));
  }, [dispatch, requestData]);

  const columns = useMemo(
    () =>
      createCampaignsColumns({
        handleEdit,
        handleViewBatches,
        handleViewFiles,
      }),
    [handleEdit, handleViewBatches, handleViewFiles],
  );

  return (
    <>
      <MainPageWrapper error={error} loading={loading} title="Campaigns">
        <DataTable
          columnVisibility={columnVisibility}
          columns={columns}
          data={campaigns}
          onColumnVisibilityChange={handleColumnVisibilityChange}
          onPaginationChange={handlePaginationChange}
          onSortingChange={handleSortingChange}
          pageCount={Math.ceil(totalCount / pagination.pageSize)}
          pageIndex={pagination.pageIndex}
          pageSize={pagination.pageSize}
          sorting={sorting}
        />
      </MainPageWrapper>

      <EditCampaignDrawer
        campaignId={selectedCampaignId!}
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onSave={handleSave}
      />
    </>
  );
};

export default CampaignList;
