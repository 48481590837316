import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { HubRoutes, TrainingPortalRoutes, StochasticRoutes } from "./routes";
import CoachesDashboard from "../modules/hub/features/CoachesDashboard/components/CoachesDashboard/CoachesDashboard";
import Logout from "../modules/hub/features/Auth/components/Logout/Logout";
import { TermsOfService } from "../components/TermsOfService/TermsOfService";

function AppRoutes() {
  return (
    <Routes>
      <Route element={<Navigate replace to="/hub" />} path="/" />

      <Route
        element={<Navigate replace to="/certain-path/coaches-dashboard" />}
        path="/certain-path"
      />

      <Route
        element={<CoachesDashboard />}
        path="/certain-path/coaches-dashboard"
      />

      <Route
        element={<TermsOfService />}
        path="/certain-path/terms-of-service"
      />

      {HubRoutes}
      {TrainingPortalRoutes}
      {StochasticRoutes}

      <Route element={<Logout />} path="/logout" />
    </Routes>
  );
}

export default AppRoutes;
