import { BreadcrumbNameMap } from "./types";

export const breadcrumbNameMap: BreadcrumbNameMap = {
  "/hub": { name: "Hub", clickable: true },
  "/hub/courses": { name: "Courses", clickable: true },
  "/hub/users": { name: "Users", clickable: true },
  "/hub/document-library": { name: "Document Library", clickable: false },
  "/training-portal": { name: "Training Portal", clickable: true },
  "/training-portal/courses": { name: "Courses", clickable: true },
};
