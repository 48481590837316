import { ColumnDef } from "@tanstack/react-table";

import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import React from "react";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../../../../../../components/catalyst-ui-kit/dropdown";
import { Company } from "../../slices/companiesSlice";

interface CreateCompaniesColumnsProps {
  handleEdit: (uuid: string) => void;
}

export function createCompaniesColumns({
  handleEdit,
}: CreateCompaniesColumnsProps): ColumnDef<Company>[] {
  return [
    {
      header: "ID",
      accessorKey: "id",
    },
    {
      header: "Company Name",
      accessorKey: "companyName",
    },
    {
      header: "Salesforce ID",
      accessorKey: "salesforceId",
    },
    {
      header: "Intacct ID",
      accessorKey: "intacctId",
    },
    {
      header: "Marketing Enabled",
      accessorKey: "marketingEnabled",
      cell: ({ row }) => (row.original.marketingEnabled ? "Yes" : "No"),
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => (
        <Dropdown>
          <DropdownButton className="p-1" plain>
            <EllipsisVerticalIcon className="h-5 w-5 text-zinc-500 dark:text-zinc-400" />
          </DropdownButton>
          <DropdownMenu>
            <DropdownItem onClick={() => handleEdit(row.original?.uuid || "")}>
              Edit Company
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      ),
      enableSorting: false,
    },
  ];
}
