import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { StochasticProspect } from "../../../../../../api/fetchStochasticProspects/types";

export function createStochasticProspectsColumns(): ColumnDef<StochasticProspect>[] {
  return [
    {
      header: "ID",
      accessorKey: "id",
    },
    {
      header: "Full Name",
      accessorKey: "fullName",
    },
    {
      header: "First Name",
      accessorKey: "firstName",
      cell: ({ row }) => row.original.firstName ?? "N/A",
    },
    {
      header: "Last Name",
      accessorKey: "lastName",
      cell: ({ row }) => row.original.lastName ?? "N/A",
    },
    {
      header: "Address 1",
      accessorKey: "address1",
      cell: ({ row }) => row.original.address1 ?? "N/A",
    },
    {
      header: "City",
      accessorKey: "city",
      cell: ({ row }) => row.original.city ?? "N/A",
    },
    {
      header: "State",
      accessorKey: "state",
      cell: ({ row }) => row.original.state ?? "N/A",
    },
    {
      header: "Postal Code",
      accessorKey: "postalCode",
      cell: ({ row }) => row.original.postalCode ?? "N/A",
    },
    {
      header: "Preferred",
      accessorKey: "isPreferred",
      cell: ({ row }) => (row.original.isPreferred ? "Yes" : "No"),
    },
    {
      header: "Do Not Mail",
      accessorKey: "doNotMail",
      cell: ({ row }) => (row.original.doNotMail ? "Yes" : "No"),
    },
    {
      header: "Do Not Contact",
      accessorKey: "doNotContact",
      cell: ({ row }) => (row.original.doNotContact ? "Yes" : "No"),
    },
    {
      header: "Company Name",
      accessorKey: "companyName",
    },
    {
      header: "Created At",
      accessorKey: "createdAt",
      cell: ({ row }) =>
        row.original.createdAt
          ? new Date(row.original.createdAt).toLocaleDateString()
          : "N/A",
    },
    {
      header: "Updated At",
      accessorKey: "updatedAt",
      cell: ({ row }) =>
        row.original.updatedAt
          ? new Date(row.original.updatedAt).toLocaleDateString()
          : "N/A",
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => (
        <div>
          <button onClick={() => alert(`Prospect ID: ${row.original.id}`)}>
            View
          </button>
        </div>
      ),
      enableSorting: false,
    },
  ];
}
