import React from "react";
import { Helmet } from "react-helmet-async";
import HVACDashboard from "../../../../../../components/Dashtail/HVACDashboard";

const CoachesDashboard: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>Coaches Dashboard | Certain Path</title>
      </Helmet>
      <HVACDashboard />
    </div>
  );
};

export default CoachesDashboard;
