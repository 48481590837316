import React from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  SortingState,
  ColumnDef,
  PaginationState,
  OnChangeFn,
  VisibilityState,
} from "@tanstack/react-table";
import { Table } from "../catalyst-ui-kit/table";
import DataTableToolbar from "./components/DataTableToolbar/DataTableToolbar";
import DataTableHeader from "./components/DataTableHeader/DataTableHeader";
import DataTableBody from "./components/DataTableBody/DataTableBody";
import DataTablePagination from "./components/DataTablePagination/DataTablePagination";

interface DataTableProps<TData extends object> {
  data: TData[];
  columns: ColumnDef<TData>[];
  pageCount: number;
  pageIndex: number;
  pageSize: number;
  sorting: SortingState;
  onSortingChange: OnChangeFn<SortingState>;
  onPaginationChange: OnChangeFn<PaginationState>;
  columnVisibility: VisibilityState;
  onColumnVisibilityChange: OnChangeFn<VisibilityState>;
}

function DataTable<TData extends object>({
  data,
  columns,
  pageCount,
  pageIndex,
  pageSize,
  sorting,
  onSortingChange,
  onPaginationChange,
  columnVisibility,
  onColumnVisibilityChange,
}: DataTableProps<TData>) {
  const table = useReactTable({
    data,
    columns,
    pageCount,
    state: {
      pagination: { pageIndex, pageSize },
      sorting,
      columnVisibility,
    },
    onPaginationChange,
    onSortingChange,
    onColumnVisibilityChange,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
  });

  return (
    <div className="flex flex-col h-full">
      <DataTableToolbar<TData> table={table} />
      <div className="flex-grow overflow-auto">
        <Table>
          <DataTableHeader<TData> headerGroups={table.getHeaderGroups()} />
          <DataTableBody<TData> rows={table.getRowModel().rows} />
        </Table>
      </div>
      <DataTablePagination<TData> table={table} />
    </div>
  );
}

export default DataTable;
