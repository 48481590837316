import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../../../../../../components/catalyst-ui-kit/dropdown";
import { CampaignFile } from "../../../../../../api/fetchCampaignFiles/types";

interface CreateCampaignFileColumnsProps {
  handleDownload: (file: CampaignFile) => void;
}

export function createCampaignFileColumns({
  handleDownload,
}: CreateCampaignFileColumnsProps): ColumnDef<CampaignFile>[] {
  return [
    {
      header: "Original Filename",
      accessorKey: "originalFilename",
    },
    {
      header: "Content Type",
      accessorKey: "contentType",
    },
    {
      header: "Created At",
      accessorKey: "createdAt",
      cell: ({ row }) =>
        row.original.createdAt
          ? new Date(row.original.createdAt).toLocaleString()
          : "N/A",
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => (
        <Dropdown>
          <DropdownButton className="p-1" plain>
            <EllipsisVerticalIcon className="h-5 w-5 text-zinc-500 dark:text-zinc-400" />
          </DropdownButton>
          <DropdownMenu>
            <DropdownItem onClick={() => handleDownload(row.original)}>
              Download
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      ),
      enableSorting: false,
    },
  ];
}
