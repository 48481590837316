import { HomeIcon } from "@heroicons/react/24/outline";
import { NavigationItem } from "../types";

const trainingPortalNavigation: NavigationItem[] = [
  {
    name: "Dashboard",
    href: "/training-portal",
    icon: HomeIcon,
    current: false,
    internalName: "dashboard",
    permissions: [],
  },
];

export default trainingPortalNavigation;
