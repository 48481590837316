import { ColumnDef } from "@tanstack/react-table";
import { QuickBooksReport } from "../../../../../../api/fetchQuickBooksReports/types";
import {
  Dropdown,
  DropdownButton,
  DropdownMenu,
  DropdownItem,
} from "../../../../../../components/catalyst-ui-kit/dropdown";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import React from "react";

interface QuickBooksReportsColumnsProps {
  handleDownloadReport: (reportId: string) => void;
}

export function createQuickBooksReportsColumns({
  handleDownloadReport,
}: QuickBooksReportsColumnsProps): ColumnDef<QuickBooksReport>[] {
  return [
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Date",
      accessorKey: "date",
      cell: ({ row }) => {
        const date = new Date(row.original.date);
        return date.toLocaleDateString(undefined, { timeZone: "UTC" });
      },
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => (
        <Dropdown>
          <DropdownButton className="p-1" plain>
            <EllipsisVerticalIcon className="h-5 w-5 text-zinc-500 dark:text-zinc-400" />
          </DropdownButton>
          <DropdownMenu>
            <DropdownItem
              onClick={() => handleDownloadReport(row.original.uuid)}
            >
              Download
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      ),
      enableSorting: false,
    },
  ];
}
