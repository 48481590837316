import React from "react";
import { Route } from "react-router-dom";
import CustomerList from "../../modules/stochastic/features/CustomerList/components/CustomerList/CustomerList";
import ProspectList from "../../modules/stochastic/features/ProspectList/components/ProspectList/ProspectList";
import MainLayout from "../../components/MainLayout/MainLayout";
import { AuthenticationGuard } from "../../components/AuthenticationGuard/AuthenticationGuard";
import CampaignList from "../../modules/stochastic/features/CampaignManagement/components/CampaignList/CampaignList";
import CampaignBatchList from "../../modules/stochastic/features/CampaignBatchManagement/components/CampaignBatchList/CampaignBatchList";
import BatchProspectList from "../../modules/stochastic/features/BatchProspectManagement/components/BatchProspectList/BatchProspectList";
import CampaignFilesList from "../../modules/stochastic/features/CampaignFileManagement/components/CampaignFilesList/CampaignFilesList";

const StochasticRoutes = (
  <Route
    element={
      <AuthenticationGuard
        component={() => <MainLayout section="stochastic" />}
      />
    }
    path="/stochastic"
  >
    <Route element={<ProspectList />} index />
    <Route element={<CustomerList />} path="customers" />
    <Route element={<ProspectList />} path="prospects" />
    <Route element={<CampaignList />} path="campaigns" />
    <Route
      element={<BatchProspectList />}
      path="campaigns/:campaignId/batches/:batchId/prospects"
    />
    <Route
      element={<CampaignBatchList />}
      path="campaigns/:campaignId/batches"
    />
    <Route element={<CampaignFilesList />} path="campaigns/:campaignId/files" />
  </Route>
);

export default StochasticRoutes;
