import React from "react";
import { Table as ReactTableInstance } from "@tanstack/react-table";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

interface DataTableToolbarProps<TData extends object> {
  table: ReactTableInstance<TData>;
}

function DataTableToolbar<TData extends object>({
  table,
}: DataTableToolbarProps<TData>) {
  return (
    <div className="mb-4 flex justify-between">
      {/* Column visibility controls */}
      <Menu as="div" className="relative inline-block text-left">
        <MenuButton className="flex items-center bg-secondary text-white px-4 py-2 rounded-md dark:bg-primary hover:bg-secondary-dark focus:outline-none">
          <span>Columns</span>
          <ChevronDownIcon className="w-5 h-5 ml-2 -mr-1" />
        </MenuButton>
        <MenuItems className="absolute left-0 z-10 mt-2 w-56 bg-white dark:bg-gray-800 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {table.getAllLeafColumns().map((column) => (
            <MenuItem key={column.id}>
              {({ active }) => (
                <div
                  className={`${
                    active ? "bg-gray-100 dark:bg-gray-700" : ""
                  } px-4 py-2 cursor-pointer`}
                  onClick={() => column.toggleVisibility()}
                >
                  <div className="flex items-center space-x-2">
                    <input
                      checked={column.getIsVisible()}
                      className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                      onChange={() => column.toggleVisibility()}
                      onClick={(e) => e.stopPropagation()}
                      type="checkbox"
                    />
                    <span>{column.columnDef.header as string}</span>
                  </div>
                </div>
              )}
            </MenuItem>
          ))}
        </MenuItems>
      </Menu>

      {/* Page size controls */}
      <Menu as="div" className="relative inline-block text-left">
        <MenuButton className="flex items-center px-4 py-2 bg-secondary text-white rounded-md dark:bg-primary">
          Show {table.getState().pagination.pageSize}
          <ChevronDownIcon className="w-5 h-5 ml-2 -mr-1" />
        </MenuButton>
        <MenuItems className="absolute right-0 z-10 mt-2 w-40 bg-white dark:bg-gray-800 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {[10, 20, 30, 40, 50].map((ps) => (
            <MenuItem key={ps}>
              {({ active }) => (
                <div
                  className={`${
                    active ? "bg-gray-100 dark:bg-gray-700" : ""
                  } px-4 py-2 cursor-pointer`}
                  onClick={() => table.setPageSize(ps)}
                >
                  Show {ps}
                </div>
              )}
            </MenuItem>
          ))}
        </MenuItems>
      </Menu>
    </div>
  );
}

export default DataTableToolbar;
