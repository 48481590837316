import React from "react";

export const TermsOfService: React.FC = () => {
  return (
    <div className="max-w-3xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">CertainPath Privacy Policy</h1>
      <p>
        <strong>Effective Date:</strong> [Insert Effective Date]
      </p>

      <p>
        At CertainPath (&ldquo;CertainPath,&rdquo; &ldquo;we,&rdquo;
        &ldquo;us,&rdquo; or &ldquo;our&rdquo;), we are committed to protecting
        your privacy and ensuring the security of your personal information.
        This Privacy Policy (&ldquo;Policy&rdquo;) explains how we collect, use,
        disclose, and safeguard your personal information when you use our
        website www.certainpath.com (the &ldquo;Site&rdquo;) and our
        software-as-a-service (SaaS) platform, coaching, and training services
        (collectively, the &ldquo;Services&rdquo;). Please read this Policy
        carefully to understand our practices regarding your personal
        information.
      </p>

      <h2>1. Scope and Application</h2>
      <p>This Policy applies to all personal information collected through:</p>
      <ul>
        <li>Our Site and Services.</li>
        <li>
          Your interactions with us, including emails, phone calls, and other
          communications.
        </li>
        <li>Events, webinars, and training sessions hosted by CertainPath.</li>
      </ul>

      <h2>2. Personal Information We Collect</h2>
      <h3>a. Information You Provide Directly</h3>
      <ul>
        <li>
          <strong>Account Registration:</strong> When you create an account, we
          collect your name, email address, phone number, company name, job
          title, and password.
        </li>
        <li>
          <strong>Payment Information:</strong> For purchasing our Services, we
          collect billing information such as credit card numbers and billing
          addresses.
        </li>
        <li>
          <strong>Profile Information:</strong> You may choose to provide
          additional information in your profile, such as a profile picture,
          professional background, and preferences.
        </li>
        <li>
          <strong>Communication Data:</strong> When you communicate with us or
          use our Services, we collect information about your communications and
          any information you choose to provide.
        </li>
      </ul>

      <h3>b. Information We Collect Automatically</h3>
      <ul>
        <li>
          <strong>Usage Data:</strong> We collect information about how you
          interact with our Site and Services, including pages visited, features
          used, and time spent on the platform.
        </li>
        <li>
          <strong>Device Information:</strong> We collect information about the
          devices you use to access our Services, including device type,
          operating system, browser type, and IP address.
        </li>
        <li>
          <strong>Cookies and Similar Technologies:</strong> We use cookies and
          similar tracking technologies to collect information about your
          browsing activities and preferences.
        </li>
      </ul>

      <h3>c. Information from Third Parties</h3>
      <ul>
        <li>
          <strong>Social Media Platforms:</strong> If you interact with us on
          social media, we may collect information based on your privacy
          settings on those platforms.
        </li>
        <li>
          <strong>Third-Party Partners:</strong> We may receive information from
          partners who assist us in providing our Services, such as payment
          processors or marketing agencies.
        </li>
      </ul>

      <h2>3. How We Use Your Personal Information</h2>
      <p>We may use your personal information for the following purposes:</p>
      <ul>
        <li>
          <strong>Service Delivery:</strong> To provide, maintain, and improve
          our Services, including our SaaS platform and coaching programs.
        </li>
        <li>
          <strong>Account Management:</strong> To manage your account,
          authenticate your identity, and provide customer support.
        </li>
        <li>
          <strong>Communication:</strong> To send administrative information,
          updates, security alerts, and support messages.
        </li>
        <li>
          <strong>Marketing and Promotions:</strong> To inform you about new
          features, events, or services that may be of interest to you. You can
          opt-out of marketing communications at any time.
        </li>
        <li>
          <strong>Analytics and Improvements:</strong> To analyze usage patterns
          and preferences to improve our Services and develop new products.
        </li>
        <li>
          <strong>Legal Compliance:</strong> To comply with legal obligations,
          resolve disputes, and enforce our agreements.
        </li>
        <li>
          <strong>Protective Measures:</strong> To protect the rights, property,
          or safety of CertainPath, our users, or others.
        </li>
      </ul>

      <h2>4. Disclosure of Personal Information</h2>
      <p>
        We may share your personal information in the following circumstances:
      </p>
      <ul>
        <li>
          <strong>Service Providers:</strong> With third-party vendors who
          perform services on our behalf, such as payment processing, data
          analysis, email delivery, hosting, and marketing assistance.
        </li>
        <li>
          <strong>Business Transactions:</strong> In connection with mergers,
          acquisitions, or asset sales, your personal information may be
          transferred.
        </li>
        <li>
          <strong>Legal Obligations:</strong> When required by law, regulation,
          or legal process, such as a court order or subpoena.
        </li>
        <li>
          <strong>Protection of Rights:</strong> To enforce our terms and
          conditions or protect our rights.
        </li>
        <li>
          <strong>Consent:</strong> With your explicit consent or at your
          direction.
        </li>
      </ul>

      <h2>5. Aggregate and De-Identified Data</h2>
      <p>
        We may use aggregated or de-identified data that cannot reasonably be
        used to identify you for any purpose, including research and marketing.
      </p>

      <h2>6. Cookies and Similar Technologies</h2>
      <p>We use cookies and similar tracking technologies to:</p>
      <ul>
        <li>
          <strong>Enhance User Experience:</strong> Remember your settings and
          preferences.
        </li>
        <li>
          <strong>Analytics:</strong> Understand how you use our Services to
          improve functionality.
        </li>
        <li>
          <strong>Marketing:</strong> Provide personalized advertisements based
          on your interests.
        </li>
      </ul>
      <p>
        You can control cookies through your browser settings. However,
        disabling cookies may affect the functionality of our Services.
      </p>

      <h2>7. Your Rights and Choices</h2>
      <ul>
        <li>
          <strong>Access and Correction:</strong> You may access and update your
          personal information by logging into your account or contacting us.
        </li>
        <li>
          <strong>Opt-Out:</strong> You can opt-out of marketing communications
          by following the unsubscribe instructions in emails or contacting us
          directly.
        </li>
        <li>
          <strong>Do Not Track Signals:</strong> Our Site does not currently
          respond to Do Not Track signals.
        </li>
      </ul>

      <h2>8. Third-Party Services</h2>
      <p>
        Our Services may contain links to third-party websites or services. This
        Policy does not apply to those third parties, and we are not responsible
        for their content or privacy practices. We encourage you to review the
        privacy policies of any third-party sites you visit.
      </p>

      <h2>9. Children&apos;s Privacy</h2>
      <p>
        Our Services are not intended for individuals under the age of 16. We do
        not knowingly collect personal information from anyone under 16. If you
        become aware that a child has provided us with personal information,
        please contact us, and we will take steps to delete such information.
      </p>

      <h2>10. Data Security</h2>
      <p>
        We implement reasonable administrative, technical, and physical security
        measures to protect your personal information. However, no method of
        transmission over the internet or electronic storage is entirely secure.
      </p>

      <h2>11. Data Retention</h2>
      <p>
        We retain your personal information for as long as necessary to fulfill
        the purposes outlined in this Policy unless a longer retention period is
        required or permitted by law.
      </p>

      <h2>12. International Data Transfers</h2>
      <p>
        Your information may be transferred to and processed in countries other
        than your country of residence. These countries may have data protection
        laws different from those of your jurisdiction.
      </p>

      <h2>13. Changes to This Privacy Policy</h2>
      <p>
        We may update this Policy periodically. We will notify you of
        significant changes by posting the new Policy on this page and updating
        the effective date. Your continued use of the Services after any changes
        constitutes your acceptance of the new Policy.
      </p>

      <h2>14. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or our
        data practices, please contact us:
      </p>
      <address>
        CertainPath Corporate Offices
        <br />
        15301 Spectrum Drive, Suite 200
        <br />
        Addison, TX 75001
        <br />
        <br />
        (888) 909-6604
        <br />
        <a href="mailto:Info@mycertainpath.com">Info@mycertainpath.com</a>
      </address>
    </div>
  );
};
