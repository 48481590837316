import React, { useCallback, useMemo } from "react";

import { useNavigate } from "react-router-dom";
import { useCompanies } from "../../hooks/useCompanies";
import { createCompaniesColumns } from "../CompanyColumns/CompanyColumns";
import MainPageWrapper from "../../../../../../components/MainPageWrapper/MainPageWrapper";
import DataTable from "../../../../../../components/Datatable/Datatable";
import CompanyListFilters from "../CompanyListFilters/CompanyListFilters";

const CompanyList: React.FC = () => {
  const {
    companies,
    totalCount,
    loading,
    error,
    pagination,
    sorting,
    columnVisibility,
    handlePaginationChange,
    handleSortingChange,
    handleColumnVisibilityChange,
    handleFilterChange,
    filters,
  } = useCompanies();

  const navigate = useNavigate();

  const handleEdit = useCallback(
    (uuid: string) => {
      navigate(`/hub/companies/${uuid}/edit`);
    },
    [navigate],
  );

  const columns = useMemo(
    () => createCompaniesColumns({ handleEdit }),
    [handleEdit],
  );

  return (
    <MainPageWrapper error={error} loading={loading} title="Companies">
      <CompanyListFilters
        filters={filters}
        onFilterChange={(filterKey: string, value: string) => {
          handleFilterChange(filterKey, value);
          handlePaginationChange({
            pageIndex: 0,
            pageSize: pagination.pageSize,
          });
        }}
      />

      <DataTable
        columnVisibility={columnVisibility}
        columns={columns}
        data={companies}
        onColumnVisibilityChange={handleColumnVisibilityChange}
        onPaginationChange={handlePaginationChange}
        onSortingChange={handleSortingChange}
        pageCount={Math.ceil(totalCount / pagination.pageSize)}
        pageIndex={pagination.pageIndex}
        pageSize={pagination.pageSize}
        sorting={sorting}
      />
    </MainPageWrapper>
  );
};

export default CompanyList;
