import React from "react";
import { HeaderGroup } from "@tanstack/react-table";
import { TableHead, TableRow } from "../../../catalyst-ui-kit/table";
import DataTableHeaderCell from "../DataTableHeaderCell/DataTableHeaderCell";

interface DataTableHeaderProps<TData extends object> {
  headerGroups: HeaderGroup<TData>[];
}

function DataTableHeader<TData extends object>({
  headerGroups,
}: DataTableHeaderProps<TData>) {
  return (
    <TableHead>
      {headerGroups.map((headerGroup) => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <DataTableHeaderCell<TData> header={header} key={header.id} />
          ))}
        </TableRow>
      ))}
    </TableHead>
  );
}

export default DataTableHeader;
