import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { StochasticCustomer } from "../../../../../../api/fetchStochasticCustomers/types";

export function createCustomersColumns(): ColumnDef<StochasticCustomer>[] {
  return [
    {
      header: "ID",
      accessorKey: "id",
    },
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Is New Customer",
      accessorKey: "isNewCustomer",
      cell: ({ row }) => (row.original.isNewCustomer ? "Yes" : "No"),
    },
    {
      header: "Is Repeat Customer",
      accessorKey: "isRepeatCustomer",
      cell: ({ row }) => (row.original.isRepeatCustomer ? "Yes" : "No"),
    },
    {
      header: "Has Installation",
      accessorKey: "hasInstallation",
      cell: ({ row }) => (row.original.hasInstallation ? "Yes" : "No"),
    },
    {
      header: "Has Subscription",
      accessorKey: "hasSubscription",
      cell: ({ row }) => (row.original.hasSubscription ? "Yes" : "No"),
    },
    {
      header: "Count Invoices",
      accessorKey: "countInvoices",
    },
    {
      header: "Balance Total",
      accessorKey: "balanceTotal",
    },
    {
      header: "Invoice Total",
      accessorKey: "invoiceTotal",
    },
    {
      header: "Lifetime Value",
      accessorKey: "lifetimeValue",
    },
    {
      header: "First Invoiced At",
      accessorKey: "firstInvoicedAt",
      cell: ({ row }) =>
        row.original.firstInvoicedAt
          ? new Date(row.original.firstInvoicedAt).toLocaleDateString()
          : "N/A",
    },
    {
      header: "Last Invoiced At",
      accessorKey: "lastInvoicedAt",
      cell: ({ row }) =>
        row.original.lastInvoicedAt
          ? new Date(row.original.lastInvoicedAt).toLocaleDateString()
          : "N/A",
    },
    {
      header: "Company Name",
      accessorKey: "companyName",
    },
    {
      header: "Address",
      accessorKey: "addresses",
      cell: ({ row }) => {
        const addresses = row.original.addresses;

        const addressString = addresses
          .map((address) => {
            const parts = [
              address.address1,
              address.city,
              address.stateCode,
              address.postalCode,
              address.countryCode,
            ].filter(Boolean);
            return parts.join(", ");
          })
          .join(" | ");

        return addressString || "N/A";
      },
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => (
        <div>
          <button onClick={() => alert(`Customer ID: ${row.original.id}`)}>
            View
          </button>
        </div>
      ),
      enableSorting: false,
    },
  ];
}
