import React from "react";
import { flexRender, Header } from "@tanstack/react-table";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { TableHeader as TableHeaderCell } from "../../../catalyst-ui-kit/table";

interface DataTableHeaderCellProps<TData extends object> {
  header: Header<TData, unknown>;
}

function DataTableHeaderCell<TData extends object>({
  header,
}: DataTableHeaderCellProps<TData>) {
  return (
    <TableHeaderCell
      onClick={header.column.getToggleSortingHandler()}
      style={{ cursor: "pointer" }}
    >
      <div className="flex items-center">
        {flexRender(header.column.columnDef.header, header.getContext())}
        {{
          asc: (
            <ChevronUpIcon className="h-5 w-5 text-zinc-500 cursor-pointer ml-1" />
          ),
          desc: (
            <ChevronDownIcon className="h-5 w-5 text-zinc-500 cursor-pointer ml-1" />
          ),
        }[header.column.getIsSorted() as string] ?? null}
      </div>
    </TableHeaderCell>
  );
}

export default DataTableHeaderCell;
