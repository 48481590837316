import { FetchCampaignFilesRequest, FetchCampaignFilesResponse } from "./types";
import { axiosInstance } from "../axiosInstance";

/**
 * Fetches campaign files based on the provided request data.
 *
 * @param campaignId - The ID of the campaign.
 * @param requestData - The request parameters for fetching campaign files.
 * @returns A promise that resolves to the list of campaign files.
 */
export const fetchCampaignFiles = async (
  campaignId: number,
  requestData: FetchCampaignFilesRequest,
): Promise<FetchCampaignFilesResponse> => {
  const response = await axiosInstance.get<FetchCampaignFilesResponse>(
    `/api/private/campaign/${campaignId}/files`,
    {
      params: {
        ...requestData,
      },
    },
  );

  return response.data;
};
